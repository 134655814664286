import * as Sentry from '@sentry/nuxt'

Sentry.init({
  dsn: 'https://c7301fd11f19731203cf99f465ea6f9e@o4508375522017280.ingest.de.sentry.io/4508375525425232',
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 0.1,

  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
    }),
    Sentry.vueIntegration({
      tracingOptions: {
        trackComponents: true,
      },
    }),
  ],
})
